const translations = {
  en: {
    nav: {
      home: "Home",
      about: "About",
      faq: "FAQ",
      contact: "Contact",
    },
    header: {
      faq: "FAQ",
      contact: "Let's talk",
    },

    about: {
      about_title: "What do we offer",
      mobile_card_front:
        "We build mobile applications that will make your business take off!",
      mobile_card_back:
        "Do you want your business to be easily accessible from mobile phones? We turn your website or e-shop into a mobile app that your customers can install on their mobiles!",
      eshop_card_front:
        "We build your own e-shop so that your business operates 24 hours a day",
      eshop_card_back:
        "Your customers can make their purchases from the comfort of their home, any time of the day. At the same time, your business expands its borders since it becomes accessible worldwide!",
      website_card_front:
        "We create the website that will make you stand out from the rest",
      website_card_back:
        "A website adds prestige to your company and inspires trust to your customers. It is one of the main things that makes you stand out and become the first choice.",
      meet_out_team: "Meet our team",
    },
    experience: {
      faq: "Frequently asked questions",
      question_1: "Why do I need a website?",
      question_2: "How much will it cost?",
      question_3: "How long until my website is ready?",
      question_4: "I already have a website. Can you upgrade it?",
      question_5: "Why should I chose ORANA?",
      question_6:
        "I need something more specific for my business. Can you help me?",
      answer_1:
        "A website brings your customers closer to your business by showcasing your excellent work.",
      answer_2:
        "We offer affordable solutions for your needs. Do not hesitate to contact us so we can discuss your case thoroughly!",
      answer_3:
        "We present our suggestion to you in 3 weeks and after receiving your feedback we move forward!",
      answer_4:
        "We help you upgrade your old website and tranform it to a modern one!",
      answer_5:
        "Our team has big experience on web development and will help you reach a new milestone with your business! Our desire for high quality results combined with ORANA's modern design, will form the perfect internet picture of your business!",
      answer_6:
        "Contact us so we can discuss in detail and offer you exactly what you need!",
      timeline_title: "The path with ORANA",
      timeline_1_label: "Today",
      timeline_2_label: "Tommorow",
      timeline_3_label: "In three weeks",
      timeline_4_label: "Finaly",
      timeline_1_desc: "Contact us.",
      timeline_2_desc: "We discuss your needs in detail.",
      timeline_3_desc:
        "We present to you the product we built especially for you.",
      timeline_4_desc:
        "We make any necessary changes in order to personalise it to your needs.",
    },
    contact: {
      other: "Other",
      write_message: "Write your message...",
      wish: "We will be more than glad to talk to you!",
      orana_quote: "“Together, any idea for your business, becomes a reality.”",
      send_button: "Send",
      form_name: "Name",
    },
    footer: {
      section_contact_title: "Contact Us!",
      section_navigate_title: "Navigate",
      home: "Home",
      about: "About",
      faq: "FAQ",
      contact: "Contact",
      team: "Our Team",
    },
    team_member: {
      find_me: "Find me on",
      name_1: "Ioannis Horgos",
      name_2: "Ioannis Gkountras",
      name_3: "Xaris Kemalidis",
      bio_1:
        "I started my professional career as a programer and software engineer at Acta LTD, while studying in the Electrical and Computer Engineering department of Aristotle University of Thessaloniki. As my knowledge was expanding, my drive to program and create lead to what today is called Orana. Together with the other founder of Orana we decided to follow this path and do the very best we can for both us and you.",
      bio_2:
        "Electrical and Computer Engineering Student at Aristotle University of Thessaloniki. At first, programming was a pleasant hobby for me. As time went by, this hobby got bigger and bigger. Now, as a founder of Orana, I contribute to our company's projects daily, overcoming a number of problems that occur, while simultaneously adding beautiful details, distinct to Orana, that make every outcome unique.",
      bio_3:
        "Electrical and Computer Engineering Student at Aristotle University of Thessaloniki.",
    },
    thank_you: {
      thank_you_title: "Thank you for contacting us!",
      thank_you_p: "We will get back to you as soon as posible!",
      thank_you_meet: "Meet our team",
      thank_you_back: "Back to Orana",
    },
    error404: {
      title: "404 η σελίδα που αναζητάτε δεν βρέθηκε",
      p: "ελέγξτε ξανά το URL.",
      meet: "Γνωρίστε την ομάδα μας!",
      back: "Πίσω στην Orana",
    },
  },
  gr: {
    nav: {
      home: "Αρχική",
      about: "Πληροφορίες",
      faq: "Συχνές Ερωτήσεις",
      contact: "Επικοινωνία",
    },
    header: {
      faq: "Συχνές ερωτήσεις",
      contact: "Επικοινωνία",
    },
    about: {
      about_title: "Τι προσφέρουμε",
      mobile_card_front:
        "Κατασκευάζουμε mobile εφαρμογές που θα απογειώσουν την επιχείρηση σας",
      mobile_card_back:
        "Θέλετε η επιχείρηση σας να είναι εύκολα προσβάσιμη από το κινητό; Μετατρέπουμε την ιστοσελίδα ή το e-shop σας σε mobile app που μπορούν να εγκαταστήσουν στα κινητά τους οι πελάτες σας!",
      eshop_card_front:
        "Κατασκευάζουμε το δικό σας e-shop ώστε η επιχείρηση σας να λειτουργεί 24 ώρες το 24ωρο",
      eshop_card_back:
        "Με αυτόν τον τρόπο οι πελάτες σας μπορούν να πραγματοποιούν τις αγορές τους από την άνεση του σπιτιού τους κάθε στιγμή της ημέρας. Αυτόματα η επιχείρηση σας επεκτείνει τα σύνορα της καθώς είναι προσβάσιμη από ολόκληρο τον κόσμο!",
      website_card_front:
        " Δημιουργούμε την ιστοσελίδα που θα σας κάνει να ξεχωρίσετε από τους υπόλοιπους",
      website_card_back:
        "Η ιστοσελίδα δίνει κύρος στην επιχείρηση σας και εμπνέει εμπιστοσύνη στους πελάτες. Είναι αυτό που θα σας κάνει να ξεχωρίσετε από τους υπόλοιπους και να γίνετε η πρώτη επιλογή.",
      meet_out_team: "Γνωρίστε την ομάδα μας!",
    },
    experience: {
      faq: "Συχνές ερωτήσεις",
      question_1: "Γιατί χρειάζομαι website;",
      question_2: "Πόσο θα μου κοστίσει;",
      question_3: "Σε πόσο καιρό θα είναι έτοιμο το website μου;",
      question_4: "Έχω ήδη website. Μπορείτε να το αναβαθμίσετε;",
      question_5: "Γιατί να επιλέξω την Orana;",
      question_6:
        "Θέλω κάτι πιο εξειδικευμένο για την επιχείρηση μου. Μπορείτε να με βοηθήσετε;",
      answer_1:
        "Ένα website φέρνει πιο κοντά τον πελάτη στην επιχείριση σας αναδικνύοντας την εξαιρετική σας δουλειά.",
      answer_2:
        "Προσφέρουμε οικονομικές λύσεις για τις ανάγκες σας. Μην διστάσετε να επικοινωνήσετε μαζί μας για να συζητήσουμε αναλυτικά!",
      answer_3:
        "Είστε πρωταγωνιστής σε αυτό! Εμείς σας παρουσιάζουμε την δική μας πρόταση σε 3 εβδομάδες και αφού ακούσουμε την γνώμη σας προχωράμε παρακάτω!",
      answer_4:
        "Αναλαμβάνουμε να αναβαθμίσουμε το παλιό σας website μετατρέποντας το σε σύγχρονο!",
      answer_5:
        "Η ομάδα μας έχει μεγάλη εμπειρία στον χώρο και θα σας βοηθήσει να φτάσετε την επιχείρηση σας ένα βήμα παραπέρα! Η όρεξη για ποιοτικό αποτέλεσμα σε συνδυασμό με το σύγχρονο σχεδιασμό που σας προσφέρει η Orana, θα αποτελέσουν την ιδανική διαδικτυακή βιτρίνα σας!",
      answer_6:
        "Επικοινωνήστε μαζί μας ώστε να συζητήσουμε αναλυτικά και να σας προσφέρουμε ακριβώς αυτό που ζητάτε!",
      timeline_title: "Η πορεία με την ORANA",
      timeline_1_label: "Σήμερα",
      timeline_2_label: "Αύριο",
      timeline_3_label: "Σε τρείς εβδομάδες",
      timeline_4_label: "Τελικά",
      timeline_1_desc: "Επικοινωνείτε μαζί μας.",
      timeline_2_desc: "Συζητούμε αναλυτικά το όραμα και τις ανάγκες σας",
      timeline_3_desc:
        "Σας παρουσιάζουμε το προιόν που φτιάξαμε ειδικά για εσάς.",
      timeline_4_desc:
        "Βλέπουμε μαζί τι αλλαγές χρειάζεται για να το φέρουμε ακριβώς στα μέτρα σας.",
    },
    contact: {
      other: "'Αλλο",
      write_message: "Γράψτε το μήνυμά σας...",
      wish: "Θα χαρούμε να σας ακούσουμε!",
      orana_quote:
        "“Μαζί οποιαδήποτε ιδέα για την επιχείρηση σας, θα γίνει πραγματικότητα.”",
      send_button: "Αποστολή",
      form_name: "Όνομα",
    },
    footer: {
      section_contact_title: "Επικοινωνήστε μαζί μας!",
      section_navigate_title: "Πλοήγηση",
      home: "Αρχική",
      about: "Πληροφορίες",
      faq: "Συχνές Ερωτήσεις",
      contact: "Επικοινωνία",
      team: "Η ομάδα μας",
    },
    team_member: {
      find_me: "Βρείτε με και εδώ:",
      name_1: "Ιωάννης Χόργκος",
      name_2: "Ιωάννης Γκούντρας",
      name_3: "Χάρης Κεμαλίδης",
      bio_1:
        "Παράλληλα με τις σπουδές μου στο τμήμα Ηλεκτρολόγων Μηχανικών και Μηχανικών Υπολογιστών του Αριστοτέλειου Πανεπιστημίου Θεσσαλονίκης, ξεκίνησα την επαγγελματική μου καριέρα ως προγραμματιστής και μηχανικός ανάπτυξης λογισμικού στην εταιρία Acta LTD. Όσο οι γνώσεις μου πλήθαιναν, το ενδιαφέρον μου για τον προγραμματισμό μαζί με την όρεξη μου για δημιουργία οδήγησαν σε αυτό που σήμερα βρίσκεται κάτω από το όνομα Orana. Έτσι, μαζί με τον άλλον ιδρυτή της Orana, αποφασίσαμε να ακολουθήσουμε αυτό το μονοπάτι και να κάνουμε ότι καλύτερο μπορούμε για εσάς και για εμάς.",
      bio_2:
        "Φοιτητής στο τμήμα Ηλεκτρολόγων Μηχανικών και Μηχανικών Υπολογιστών του Αριστοτέλειου Πανεπιστημίου Θεσσαλονίκης. Αρχικά για εμένα ο προγραμματισμός ήταν ενα ευχάριστο hobby. Με τον καιρό αυτή η ενασχόληση μου με τον προγραμματισμό απέκτησε μεγαλύτερες διαστάσεις. Πλέον ως ιδρυτής της Orana, ασχολούμαι σε καθημερινή βάση με projects της εταιρείας, ξεπερνώντας πόλλα προβλήματα που προκύπτουν, αλλά και προσθέτοντας μια μοναδική πινελιά, χαρακτηριστική της Orana, που κάνει το κάθε αποτέλεσμα να είναι ξεχωριστό.",
      bio_3:
        "Φοιτητής στο τμήμα Ηλεκτρολόγων Μηχανικών και Μηχανικών Υπολογιστών του Αριστοτέλειου Πανεπιστημίου Θεσσαλονίκης.",
    },
    thank_you: {
      thank_you_title: "Ευχαριστούμε που επικοινωνήσατε μαζί μας!",
      thank_you_p: "Θα απαντήσουμε το συντομότερο δυνατό!",
      thank_you_meet: "Γνωρίστε την ομάδα μας",
      thank_you_back: "Πίσω στην Orana",
    },
    error404: {
      title: "404 η σελίδα που αναζητάτε δεν βρέθηκε",
      p: "ελέγξτε ξανά το URL.",
      meet: "Γνωρίστε την ομάδα μας!",
      back: "Πίσω στην Orana",
    },
  },
};

export default function translate(language) {
  if (language === "en") return translations.en;
  else if (language === "gr") return translations.gr;
}
